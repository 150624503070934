import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import HospiceHeader from "../../../components/src/HospiceHeader";
import UnauthorizedFooter from "../../../components/src/UnauthorizedFooter";
import StripePayment from "../../../components/src/StripePayment";
import { styled } from '@mui/material/styles'
import {Typography as MaterialT} from '@mui/material';
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
  IPlan,
  IProject
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return   <HospiceHeader
    data-test-id={"authorized-header"}
    hideMenu
  />
  }
  renderAnnual = (interval: string) => {
    const HAFT_MONTH = "bi-annual"
    const FULL_MONTH = "annual"
    if(interval === "6"){
      return HAFT_MONTH
    }
    return FULL_MONTH
  }
  renderCardPlan = (plan: IPlan, project: IProject) => {
    return <div key={plan.id.toString()} style={{display: 'flex', flexDirection: 'column', borderRadius: 16,  background: "#fff", paddingTop: 5, paddingRight: 5, paddingLeft: 18, paddingBottom: 30, boxShadow: "0px 8px 32px 0px #0000000F"}}>
        <LatoText style={{backgroundColor: "#D5F7CA", paddingTop: 6, paddingBottom: 6, paddingLeft: 12, paddingRight: 12, borderRadius: 12,alignSelf: 'flex-end', color: "#343C32", fontWeight: "700", fontSize: 14 }}>{this.renderAnnual(plan.interval)}</LatoText>
        <LatoText style={{color: "#586554", fontSize: 20, fontWeight: "700",marginTop: 18}}>{plan.interval}-Month Plan</LatoText>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: 10}}>
        <TextPrice style={{color: "#343C32", fontSize: 36, fontWeight: "800"}}>$ {plan.price}</TextPrice>
        {plan.discount_details !== "" && <TextDiscount style={{color: "#A7B3A3", fontSize: 12, fontWeight: "600",marginLeft: 2}}>{plan.discount_details}</TextDiscount>}
        </div>
        <Button text="Start Now"
        loading={this.state.loading && this.state.selectedPlan?.id === plan.id}
        onPress={() => {
          this.handleSubscribePlan(plan.stripe_price_id, plan.id.toString(), {...plan, name: project.attributes.name})
        }}
      data-test-id="button-choose-plan"
       textStyle={{fontFamily: "Lato", color: "#fff" }}
       style={{backgroundColor: '#5C9748', width: 143, marginTop: 28, }}
       />
       <LatoText style={{color: "#A7B3A3", fontSize: 14, marginTop: 12, marginRight: 18}}>Your subscription will renew after {plan.interval} months.</LatoText>
    </div>
  }
  renderProjects = () => {
    return this.state.listProject.map(project => {
      return project.attributes.plans.map(plan => {
        return this.renderCardPlan(plan, project)
      })
    })
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ height: "100%" }}>
        {this.state.stripeClientSecret && <StripePayment data-test-id="information-form" onClose={this.onCloseModal} plan={this.state.selectedPlan} isShowModal={this.state.isShowModalPayment} stripeClientSecret={this.state.stripeClientSecret} />}
        <div style={{ display: "flex", flexDirection: 'column', flex: 1, height: "100%", background: "#FAF9F6" }}>
          <div style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20, }}>
            {this.renderHeader()}

          </div>
          <div style={{ display: "flex", flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <LatoText style={{ fontWeight: "700", fontSize: 32, color: "#343C32" }}>Choose Your Plan</LatoText>
            <LatoText style={{ fontWeight: "700", fontSize: 14, color: "#7C8E76", marginTop: 8}}>Risk-free trial: You can cancel your plan within the first month for a full refund.</LatoText>
            <div style={{ display: "flex", flexDirection: 'row', marginTop: 40, columnGap: 22 }}>
              {this.renderProjects()}
            </div>
          </div>
          <UnauthorizedFooter data-test-id="footer" goToPP={this.goToPrivacyPolicy} goToTAC={this.goToTermsAndCondition} goToCU={this.goToContactUs} />
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const LatoText = styled(MaterialT)({
  fontFamily: "Lato",
});
const LonText = styled(Typography)({
  fontFamily: "Lato",
});
const TextPrice = styled(MaterialT)({
  fontFamily: "Lato",
  fontWeight: "400",
  lineHeight: "43px"

});
const TextDiscount = styled(MaterialT)({
  fontFamily: "Lato",
  fontWeight: "400",
  lineHeight: "14.4px",
  marginBottom: 3
});
const FormInput = styled(Input)({
  width: "100%",
  borderRadius: "8px",
  
});
// Customizable Area End
