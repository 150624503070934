Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_dashboard/dashboard";
exports.patientEndpoint = "bx_block_cfpingenerator/add_patients"
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.listOfPatents = "List of Patients"
exports.hideExpired = "Hide Expired Patients"
exports.placeHolderSearch = "Search Patients or Caregivers"
exports.btnAddPatient = "Add Patient"
exports.headSNo = "S.No."
exports.headPatient = "Patient"
exports.headStatusOfPatients = "Status of  Patients"
exports.headCaregiver = "Caregiver"
exports.headRelation = "Relation"
exports.headNoOfDevices = "No. of Devices"
exports.headPhoneNumber = "Phone Number"
exports.headEmail = "Email"
exports.headResendEmail = "Resend Email"
exports.noteCaregiver = "Up to 3 caregivers can be added to each patient"
exports.noteNoOfDevices = "Each caregiver will be allowed to use Partner for Care on 3 different devices (e.g. mobile phone, laptop, tablet)"
exports.notePhoneNumber = "Caregivers will use their phone number as their unique pin to log in"
exports.noteEmail = "Each caregiver will automatically receive an email with the link to Partner for Care"
exports.noteResendEmail = "Click the email icon next to a Caregiver to resend them the welcome email and website link"
exports.headerModalTitle = "Resend Welcome Email?"
exports.modalFirstLine = "Are you sure you want to resend the welcome email to"
exports.modalSecondLine = "If a caregiver does not see the email, ask them to check their spam folder."
exports.subscribePlanEndpoint = "bx_block_stripe_integration/user_subscriptions"
// Customizable Area End