Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.patientDescription = "How many patients do you have on average per day in your hospice?"
exports.chooseNow = "Choose Now"
exports.selectYourPlan = "Select Your Plan"
exports.buttonSelect = "Select"
exports.subscriptionEndpoint = "bx_block_subscription_billing/products"
exports.subscribePlanEndpoint = "bx_block_stripe_integration/user_subscriptions"
exports.createPaymentIntentEndpoint = "bx_block_stripe_integration/subscriptions/create_payment_intent"
// Customizable Area End