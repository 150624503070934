// Customizable Area Start
import React from "react";

import {
  Box,
  Button as MaterialButton,
  IconButton,
  Input,
  TextareaAutosize,
  Typography,
  MenuItem,
  styled,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import UnauthorizedHeader from "../../../components/src/UnauthorizedHeader";

import { Formik } from "formik";
import * as Yup from "yup";

import ContactusController, { Props, configJSON } from "./ContactusController";
import AuthorizedHeader from "../../../components/src/AuthorizedHeader";
import AuthorizedFooter from "../../../components/src/AuthorizedFooter";
import ModalThanks from "../../../components/src/ModalThanks";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {listOfIssue, imgCover} from "./assets"
import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// Customizable Area End

export default class Contactus2 extends ContactusController {
  stateList: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  validationSchemaContactUs = Yup.object().shape({
    yourName: Yup.string().trim().required(configJSON.errorYourName),
    email: Yup.string().trim().email(configJSON.errorEmailNotValid),
    contactNumber: Yup.string()
      .matches(configJSON.regexContactNumber, configJSON.errorPhoneNotValid),
    issue: Yup.string().required(configJSON.errorIssue),
    describe: Yup.string().trim().required(configJSON.errorDescribeIssue),
  }).test('email-or-contactNumber', configJSON.errorPhoneNumber, function (value) {
    const { email, contactNumber } = value;
    if (!email && !contactNumber) {
      return this.createError({ path: 'email', message: configJSON.errorPhoneNumber });
    }
    return true;
  });
  getErrorClassName = (error: string | null | undefined, touched: boolean | undefined) => {
    return error && touched ? "error" : ""
  }
  isErrorField = (error: string | null | undefined, touched: boolean | undefined) => {
    return !!error && !!touched && error !== ""
  }
  renderContactUsForm = () => {
    return (
      <Formik
        data-test-id="contact-us-form"
        initialValues={this.initialContactUsForm}
        onSubmit={(values) => this.sendMessageContactUs(values)}
        validationSchema={this.validationSchemaContactUs}
        validateOnMount
      >
        {({
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          values,
          setFieldValue,
          isValid,
          touched,
          dirty
        }) => (
          <SecondFormStepContainer>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:"100%"}}>
            <MainHeader>{configJSON.headerTitle}</MainHeader>
            <SubHeader style={{ marginBottom: 16 }}>Tell us your issues or suggestions for our website!</SubHeader>

            </Box>

            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelYourName}
                </InputLabel>
                <FormInput
                  onChange={handleChange("yourName")}
                  onBlur={() => setFieldTouched("yourName")}
                  className={this.getErrorClassName(errors.yourName, touched.yourName)}
                  id="yourName"
                  data-test-id="your-name-field"
                  placeholder={configJSON.placeholderYourName}
                  disableUnderline
                />
                {this.isErrorField(errors.yourName, touched.yourName) && (
                  <ValidationParagraph data-test-id="hospice-name-error-message" className="error">
                    {errors.yourName}
                  </ValidationParagraph>
                )}
              </InputContainer>

            </FormRow>
            <InputLabel>
            How do we contact you?*
                </InputLabel>

            <FormRow>
                <InputContainer>
                  <InputLabel>
                    {configJSON.labelEmail}
                  </InputLabel>
                  <FormInput
                    onChange={handleChange("email")}
                    onBlur={() => setFieldTouched("email")}
                    className={this.getErrorClassName(errors.email, touched.email)}
                    id="email"
                    data-test-id="email-field"
                    placeholder={configJSON.placeholderEmail}
                    disableUnderline
                  />
                  {this.isErrorField(errors.email, touched.email) && (
                    <ValidationParagraph className="error">
                      {errors.email}
                    </ValidationParagraph>
                  )}
                </InputContainer>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelPhoneNumber}
                </InputLabel>
                <FormInput
                  onChange={ handleChange('contactNumber')}
                  onBlur={() => setFieldTouched("contactNumber")}
                  className={this.getErrorClassName(errors.contactNumber, touched.contactNumber)}
                  id="contactNumber"
                  data-test-id="contact-number-field"
                  placeholder={configJSON.placeholderPhoneNumber}
                  disableUnderline
                />
                {this.isErrorField(errors.contactNumber, touched.contactNumber) && (
                  <ValidationParagraph className="error">
                    {errors.contactNumber}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>
            <FormRow>

              <InputContainer>
                <InputLabel>
                  {configJSON.labelIssue}
                </InputLabel>

                <StateSelect
                  error={this.isErrorField(errors.issue, touched.issue)}
                  onChange={(event: SelectChangeEvent<any>) => {
                    setFieldValue("issue", event.target.value)
                  }}
                  onBlur={() => setFieldTouched("issue")}
                  className={this.getErrorClassName(errors.issue, touched.issue)}
                  id="issue"
                  data-test-id="issue-field"
                  disableUnderline
                  displayEmpty
                  defaultValue=""
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  renderValue={(selected: any) => {
                    const listSelectedValue = selected
                    const lengthOfListSelected = listSelectedValue.length
                    if (lengthOfListSelected === 0) {
                      return  <Typography style={{ fontFamily: "Lato", color: "#C3CBC0" }}>{configJSON.placeholderIssue}</Typography>;
                    }
                    return selected;
                  }}
                >
                  {listOfIssue.map((state: string) => (
                    <MenuItem sx={{ color: "#586554",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Lato",}} value={state} key={state}>{state}</MenuItem>
                  ))}
                </StateSelect>
                {this.isErrorField(errors.issue, touched.issue) && (
                  <ValidationParagraph className="error">
                    {errors.issue}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer>
                <InputLabel>
                  {configJSON.labelDescribeIssue}
                </InputLabel>
                <FormTextArea
                  style={{ resize: "none" }}
                  minRows={5}
                  onChange={handleChange("describe")}
                  onBlur={() => setFieldTouched("describe")}
                  className={this.getErrorClassName(errors.describe, touched.describe)}
                  id="describe"
                  data-test-id="describe-field"
                  placeholder={configJSON.placeholderDescribeIssue}
                />
                {this.isErrorField(errors.describe, touched.describe) && (
                  <ValidationParagraph className="error">
                    {errors.describe}
                  </ValidationParagraph>
                )}
              </InputContainer>
            </FormRow>
            <ConfirmButton onClick={() => { handleSubmit() }}
            loading={this.state.loading}
            loadingPosition="center"
            className={(!isValid && !dirty) || this.state.loading ? "" : "active"}
              disabled={!isValid}
              data-test-id="button-submit-info">{configJSON.textButtonSendMessage}</ConfirmButton>
          </SecondFormStepContainer>
        )}
      </Formik>)
  }
  renderModal = () => {
    return <ModalThanks
    open={this.state.isOpenModal}
    data-test-id="modal-thank"
    handleCloseModal={this.handleCloseModal}
    headerTitle="Thanks for your message"
    firstLineDes="Thank you for reaching out to us! Your support request has been received, and a member of our team will be contacting you shortly."
    secondLineDes="Please allow us approximately 48 hours to respond to your request."
    />
  }
  renderHeader = () => {
    const isAuthen = localStorage.getItem("token")
    if (isAuthen) {
      return <AuthorizedHeader
        navigation={this.props.navigation}
        data-test-id={"authorized-header"}
      />
    } else {
      return <UnauthorizedHeader />
    }
  }
  renderBreadcrumbs = () => {
    
    const containerStyle = {display: 'flex', columnGap: 10, alignItems: 'center', paddingLeft: 10, paddingTop: 4, paddingBottom: 4, borderBottom: '1px solid #F2F4F1'}
    return <div style={{...containerStyle, flexDirection: 'row'}}>
      <IconButton 
      data-test-id="button-back"
      onClick={this.goBack}>
        <ArrowBackIcon style={{color: "#343C32"}}/>
        </IconButton>
      <ButtonBread data-test-id="button-home" onClick={this.handleGoHome}>
      <BreadCrumbText 
      style={{color: "#475569", textTransform: "capitalize"}}>{this.getScreenName()}</BreadCrumbText>
      </ButtonBread>
      <KeyboardArrowRightIcon style={{color: "#C3CBC0"}}/>
      <BreadCrumbText style={{fontWeight: '700'}}>Contact Us</BreadCrumbText>
    </div> 
  }
  render() {
    const isAuthen = localStorage.getItem("token")
    let styleContainer = {}
    if(!isAuthen){
      styleContainer = {
        padding: 0
      }
    }
    return (
      <Container sx={{...styleContainer}}>
        {this.renderModal()}
        {this.renderHeader()}
        {this.renderBreadcrumbs()}
        <SignUpPageContainer>
          <RegisterFormContainer>
            {this.renderContactUsForm()}
          </RegisterFormContainer>
          <CardMediaBox>
            <img
              style={{
                width: '100%',
                borderBottomLeftRadius: 45, objectFit: 'cover',
                overflow: "hidden"
              }}
              src={imgCover}
              alt="A person holds the hand of his elderly relative"
            />
          </CardMediaBox>
        </SignUpPageContainer>
        <AuthorizedFooter
                footerFirstSentence={configJSON.footerFirstSentenceText}
                footerSecondSentence={configJSON.footerSecondSentenceText}
                navigation={this.props.navigation}
                data-test-id={"authorized-footer"}
              />
      </Container>
    );
  }
}

// Customizable Area Start
const Container = styled(Box)({
  display: 'flex', flex: 1, flexDirection: 'column', padding: 20,
  "@media (max-width: 900px)": {
    padding: 0
  },
});
const SignUpPageContainer = styled(Box)({
  display: "flex",
  flex: 1,
  padding: "10px 0 10px 0",
  flexDirection: 'row'
});

const RegisterFormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
});

const CardMediaBox = styled(Box)({
  display: "flex",
  flex: 1,
  "@media (max-width: 1180px)": {
    display: "none",
  },
});

const FormRow = styled(Box)({
  display: "flex",
  gap: "16px",
  "@media (max-width: 900px)": {
    flexDirection: "column",
  },
});
const SecondFormStepContainer = styled(Box)({
  boxSizing: 'border-box',
  height: "100%",
  width: "600px",
  paddingLeft: "16px",
  paddingRight: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "18px",
  "& button.active": {
    backgroundColor: "#7C8E76",
    color: "#FFFFFF",
  },
  "@media (max-width: 900px)": {
    width: "100%"
  },
});


const InputContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "4px",
  "& ::placeholder": {
    fontWeight: 400,
    fontFamily: "Lato",
    fontSize: "16px",
    textTransform: "none",
    color: "#C3CBC0",
  },
  "& .error": {
    borderColor: "#F87171",
    color: "#DC2626",
  },
  "& .logo-placeholder": {
    fontFamily: "Lato",
    color: "#C3CBC0",
    fontSize: "16px",
    textTransform: "none",
    fontWeight: 400,
  },
});

const InputLabel = styled(Typography)({
  fontFamily: "Lato",
  color: "#343c32ff",
  fontSize: 14,
  fontWeight: 700,
  letterSpacing: 0,
});

const FormInput = styled(Input)({
  width: "100%",
  borderRadius: "8px",
  borderStyle: "solid",
  paddingRight: "8px",
  borderWidth: "1px",
  paddingLeft: "8px",
  borderColor: "#C3CBC0",
  height: "44px",
  fontFamily: "Lato",
  '&.error .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Placeholder color when error class is present
  },
  '& .MuiInputBase-input::placeholder': {
    color: '#94A3B8', // Default placeholder color
  },
  
});

const FormTextArea = styled(TextareaAutosize)({
  width: "100%",
  maxHeight: 200,
  borderStyle: "solid",
  paddingLeft: "8px",
  borderColor: "#C3CBC0",
  borderRadius: "8px",
  fontFamily: "Lato",
  borderWidth: "1px",
  fontSize: 16,
  fontWeight: '500'
});

const MainHeader = styled(Typography)({
  letterSpacing: 0,
  fontFamily: "Lato",
  fontWeight: 700,
  color: "#343c32ff",
  fontSize: 24,
  alignSelf: 'center',
  "@media (max-width: 900px)": {
    textAlign: 'center'
  },
});
const SubHeader = styled(Typography)({
  letterSpacing: 0,
  fontFamily: "Lato",
  fontWeight: 400,
  color: "#475569",
  fontSize: 14,
  alignSelf: 'center'
});

const ValidationParagraph = styled(Typography)({
  minWidth: "100%",
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Lato",
  textAlign: "left",
  width: 0,
  lineHeight: "18px",
  color: "#7C8E76"
});

const StateSelect = styled(Select)({
  width: "100%",
  height: "44px",
  borderRadius: 8,
  "& .MuiMenuItem-root ": {
    color: "#586554",
    fontSize: "16px",  // Add your custom font-size
    fontWeight: 500,
    fontFamily: "Lato",
  },
  "&.error .MuiSelect-root": {
    borderColor: "#F87171",  // Change border color to red when error class is present
  },
  "& .MuiSelect-root": {
    borderColor: "#C3CBC0",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "8px",
    borderRadius: "8px",
    borderWidth: "1px",
    boxSizing: "border-box",
    borderStyle: "solid",
    display: "flex",
    color: "#586554",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Lato",
  },
  
});

const ConfirmButton = styled(LoadingButton)({
  fontFamily: 'Lato',
  width: "100%",
  backgroundColor: "#D6DCD5",
  color: "#7C8E76",
  height: 44,
  textTransform: 'initial',
  fontWeight: '700',
  borderRadius: 8,
  fontSize: 16,
  '& .MuiLoadingButton-loadingIndicator': {
    color: '#fff',
  }
});

const PasswordVisibilityIconButton = styled(IconButton)({
  height: "18.95px",
  width: "21.65px",
  "& svg": {
    fill: "#C3CBC0",
  },
});
const ButtonBread = styled(MaterialButton)({
  padding: '4px 8px',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#F1F5F9',
  },
});
const BreadCrumbText = styled(Typography)({
  fontSize: 16,
  fontFamily: "Lato",
  color: "#0F172A",
  "@media (max-width: 900px)": {
    fontSize: 14,
  },
})
// Customizable Area End
