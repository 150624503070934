import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {  Button } from '@builder/component-library';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    open: boolean,
    handleCloseModal: () => void,
    headerTitle: string,
    firstLineDes: string,
    secondLineDes?: string,
}
export default function ModalThanks(props: Props) {
  return (
    <Modal
    open={props.open}
    onClose={props.handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <div style={{...webStyles.modalContainer, flexDirection: 'column', borderBottomRightRadius: 32}}>
      <div style={{display: 'flex',height: 80, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 40, paddingRight: 20}}>
      <Typography style={webStyles.headerTitle}>{props.headerTitle}</Typography>
      <IconButton data-test-id="button-close-modal-thank" onClick={props.handleCloseModal}>
      <CloseIcon/>

      </IconButton>
      </div>
      <div style={{...webStyles.modalContent, flexDirection: 'column',}}>
      <Typography style={{...webStyles.modalTextContent}}>{props.firstLineDes}</Typography>
        <Typography style={{...webStyles.modalTextContent, marginTop: 16}}>{props.secondLineDes}</Typography>
      </div>
      <div style={{...webStyles.buttonModalContainer, flexDirection: 'column'}}>
      <Typography style={{...webStyles.modalTextContent, fontWeight: '700', color: "#343C32"}}>Sent from</Typography>
      <Typography style={{...webStyles.modalTextContent, fontWeight: '500', color: "#586554"}}>Partner for Care</Typography>

      </div>
    </div>
  </Modal>
  );
}
const webStyles = {
    modalContainer: {
      background: "#fff",
      display: "flex",
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      borderRadius: 8,
    },
    buttonModalContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: 30,
      marginLeft: 40,
      paddingBottom: 30
    },
    headerTitle: {
      fontFamily: "Lato",
      fontSize: 24,
      fontWeight: '700',
      color: "#586554"
    },
    modalContent: {
      border: '1px solid #D6DCD5',
      padding: "40px",
      display: 'flex',
    },
    modalTextContent: {
      fontSize: 16,
      fontFamily: "Lato",
         color: "#343C32",
         fontWeight: '500'
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
      borderRadius: 4,
      padding: 20,
      background: '#FFFFFF'
    },
}